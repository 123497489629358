import { Route, Routes } from 'react-router-dom';
//page
import Action from './scenes/action';

function App() {

  const content = (
    <Routes>
      <Route path='/' element={<Action/>}></Route>
    </Routes>
  )

  return (
    <div className="App">
      {content}
    </div>
  );
}

export default App;