import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { plPL } from '@mui/x-data-grid/locales';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import useFetch from "../../service/api/getAPI";
import { REACT_APP_API_URL } from "../../env";
import { isWeekend, isHoliday, eachDayOfInterval } from "date-fns";
import Holidays from 'date-holidays';

const Action = () => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    const [selectedDateRange, setSelectedDateRange] = useState([firstDayOfMonth, lastDayOfMonth]);
    const [workingDaysCount, setWorkingDaysCount] = useState(0);

    const formatDateToMMDDYYYY = (date) => {
        return date ? date.toLocaleDateString('en-US') : ''; 
    };

    const { data, loading, error } = useFetch(
        REACT_APP_API_URL +
        "Actions/GetActionsByTime?fromDate=" + formatDateToMMDDYYYY(selectedDateRange[0]) + 
        "&toDate=" + formatDateToMMDDYYYY(selectedDateRange[1])
    );

    useEffect(() => {
        calculateWorkingDays(selectedDateRange);
    }, [selectedDateRange]);

    const calculateWorkingDays = (dateRange) => {
        const [start, end] = dateRange;
        const hd = new Holidays("PL");
        const days = eachDayOfInterval({ start, end });
    
        const workingDays = days.filter((date) => {
            return !isWeekend(date) && !hd.isHoliday(date);
        });
    
        setWorkingDaysCount(workingDays.length);
    };

    if (loading) return <Loading />;
    if (error?.length > 0) return <Error err={error} />;

    const averageRow = {
        id: 'avg',
        countActions: data.averageCountActions,
        time: data.averageTime,
        ozsFullName: "Średnia",
        vacation: {
            vacationDays: data.averageVacationDays
        },
    };
    
    const totalRow = {
        id: 'total', 
        countActions: data.totalCountActions,
        time: data.totalTime,
        ozsFullName: "Suma", 
        vacation: {
            vacationDays: data.totalCountVacationDays 
        },
    };
    
    const rowsWithTotals = [...data.actions, averageRow, totalRow];

    const columns = [
        { 
            field: "countActions", 
            headerName: "Ilość zamkniętych zgłoszeń", 
            flex: 1, 
            renderCell: (params) => {
                const isAvgRow = params.row.id === 'avg';
                const isTotalRow = params.row.id === 'total';
                const color = isAvgRow || isTotalRow ? "red" : "inherit"; 
                const fontWeight = isAvgRow ? 'bold' : 'normal'; 
                return (
                    <span style={{ color, fontWeight }}>
                        {params.value}
                    </span>
                );
            },
        },
        { 
            field: "countActionAverageByDepartment", 
            headerName: "Odniesienie do średniej w dziale", 
            flex: 1, 
            renderCell: (params) => {
                const value = params.value; 
                const color = value < 1 ? "red" : "green"; 
                const fontWeight = 'bold';
                return (
                    <span style={{ color, fontWeight }}>
                        {value}
                    </span>
                );
            },

        },
        { 
            field: "time", 
            headerName: "Czas zaraportowany pod zgłoszeniami [h]", 
            flex: 1, 
            renderCell: (params) => {
                const isAvgRow = params.row.id === 'avg';
                const isTotalRow = params.row.id === 'total';
                const color = isAvgRow || isTotalRow ? "red" : "inherit"; 
                const fontWeight = isAvgRow ? 'bold' : 'normal'; 
                return (
                    <span style={{ color, fontWeight }}>
                        {params.value}
                    </span>
                );
            },
        },
        { 
            field: "timeAverageByDepartment", 
            headerName: "Odniesienie do średniej w dziale", 
            flex: 1, 
            renderCell: (params) => {
                const value = params.value;
                const color = value < 1 ? "red" : "green"; 
                const fontWeight = 'bold';
                return (
                    <span style={{ color, fontWeight }}>
                        {value}
                    </span>
                );
            },
        },
        { 
            field: "ozsFullName", 
            headerName: "OZS", 
            flex: 1, 
            renderCell: (params) => {
                const isSummaryRow = params.row.id === 'avg' || params.row.id === 'total';
                const color = isSummaryRow ? "red" : "inherit";
                const fontWeight = isSummaryRow ? 'bold' : 'normal';
                return (
                    <span style={{ color, fontWeight }}>
                        {params.value}
                    </span>
                );
            },
        },
        { 
            field: "vacation.vacationDays", 
            headerName: "", 
            flex: 1, 
            renderCell: (params) => {
                return (
                    <span style={{ fontWeight: 'bold' }}>
                        {params.row.vacation.vacationDays}
                    </span>
                );
            },
            renderHeader: () => (
                <span style={{ color: '#2596be', fontWeight: 'bold' }}>Liczba dni urlopu</span>
            ),
        }
    ];
    
    const handleDateRangeChange = (value) => {
        if (value === null) {
            setSelectedDateRange([firstDayOfMonth, currentDate]);
        } else {
            setSelectedDateRange(value);
        }
    };

    return (
        <Box margin="20px">
            <Box display="flex" justifyContent="space-between">
                <Typography style={{ color: "#ed1111", fontFamily: "Bold" }} variant="h3">Tkomp</Typography>
                <Typography style={{ color: "#8c8b8b", fontFamily: "Bold", marginTop: "12px" }} variant="h4">Raport z działań na serwisie</Typography>
            </Box>
            <Box>
                <DateRangePicker
                    style={{ marginTop: "50px" }}
                    value={selectedDateRange}
                    onChange={handleDateRangeChange}
                />
            </Box>
            <Typography style={{ color: "#a8a8a8", marginLeft: "5px" }} variant="p">
                Wybrany zakres dat:{" "}
                {selectedDateRange[0] && selectedDateRange[0].toLocaleDateString('pl-PL', { 
                    day: '2-digit', 
                    month: '2-digit', 
                    year: 'numeric' 
                })} -{" "}
                {selectedDateRange[1] && selectedDateRange[1].toLocaleDateString('pl-PL', { 
                    day: '2-digit', 
                    month: '2-digit', 
                    year: 'numeric' 
                })} |
            </Typography>
            <Typography style={{ color: "#2596be", marginLeft: "5px", fontWeight: 'bold' }} variant="p">
                    Liczba dni roboczych: {workingDaysCount}
            </Typography>
            <Box marginTop="30px">
                <Box margin="40px 0px 0px 0px" sx={{
                    "& .MuiDataGrid-root": {},
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                        fontSize: '1rem',
                        textAlign: 'center' 
                    },
                    "& .MuiDataGrid-columnHeader": {
                        backgroundColor: "#e3dcdd",
                        borderBottom: "none",
                        fontSize: '1.1rem',
                        textAlign: 'center' 
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: "#f5f5f5"
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: "#e3dcdd"
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${"#e0e0e0"} !important`,
                        fontSize: '1rem',
                    },
                    "& .totals-row": {
                        backgroundColor: "#e8e6e6",
                    },
                    "& .totals-cell": {
                        color: "#ed1111",
                    }
                }}>
                    <DataGrid
                        rows={rowsWithTotals}
                        columns={columns}
                        components={{ Toolbar: GridToolbar }}
                        localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
                        autoHeight
                        disableSelectionOnClick
                        getRowClassName={(params) => {
                            if (params.row.id === 'avg') return 'totals-row';
                            if (params.row.id === 'total') return 'totals-row';
                            return '';
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Action;
