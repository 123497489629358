import { Typography, Box, useTheme } from "@mui/material";

const Header = ({title, subtitle}) => {
    const theme = useTheme();

    return(
        <Box mb="30px">
            <Typography variant="h2" color="#416e6a" fontWeight="bold" sx={{ mb: "5px"}}>{title}</Typography>
            <Typography variant="h5" color="#ad1c2a">{subtitle}</Typography>
        </Box>
    );
}

export default Header;